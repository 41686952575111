import { Observable } from 'rxjs';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENV } from '@app/env';
import { HttpStatusCodeApiService } from './http-status-code-api.service';

@Injectable({
	providedIn: 'root'
})
export class Api {
	private baseUrl: string;

	constructor(
		protected http: HttpClient,
		protected httpStatusCodeApiService: HttpStatusCodeApiService,
	) {
		this.baseUrl = ENV.baseUrl;
	}

	protected getOptions<B>(body?: B): Record<string, unknown> {
		const options: Partial<Record<string, unknown>> = {};
		if (body) {
			options['body'] = JSON.stringify(body);
		}
		return options;
	}

	public sendGetRequest<T, R>(
		url: string,
		body: T | null = null
	): Observable<R> {
		return this.sendRequest(url, 'get', body);
	}

	public sendPutRequest<T, R>(
		url: string,
		body: T | null = null
	): Observable<R> {
		return this.sendRequest(url, 'put', body);
	}

	public sendPostRequest<T, R>(
		url: string,
		body: T | null = null
	): Observable<R> {
		return this.sendRequest(url, 'post', body);
	}

	public sendDeleteRequest<T, R>(
		url: string,
		body: T | null = null
	): Observable<R> {
		return this.sendRequest(url, 'delete', body);
	}

	public setBaseUrl(baseUrl: string): void {
		this.baseUrl = baseUrl;
	}

	/**
	 * @deprecated
	 * Будет приватным приватным
	 * @param url
	 * @param method
	 * @param body
	 */
	public sendRequest<T, R>(
		url: string,
		method: string,
		body: T | null = null
	): Observable<R> {
		return new Observable((subscriber) => {
			this.http
				.request<R>(method, this.baseUrl + url, this.getOptions(body))
				.subscribe({
					next: (result) => {
						subscriber.next(result);
						subscriber.complete();
					},
					error: ({error}) => {
						this.httpStatusCodeApiService.setError(error);
						subscriber.complete();
					},
					complete: () => {
						subscriber.complete();
					}
				});
		});
	}
}
