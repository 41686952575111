import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ConnectInfo, User } from 'src/app/core/interfaces/users';
import { Router } from '@angular/router';
import { HttpStatusCodeApiService } from 'src/app/core/services/api/http-status-code-api.service';
import { LocalStorageService } from '../../../core/services/localStorage.service';
import { UsersApiService } from '../../../core/services/api/user/users-api.service';

@Component({
	selector: 'app-profile-card',
	templateUrl: './profile-card.component.html',
	styleUrls: ['./profile-card.style.scss'],
})
export class ProfileCardComponent implements OnInit {
	@Input() user!: User;

	@Input() userId!: number;

	@Input() isRemote: boolean = false;

	public userName = '';

	public features: string[] = [];

	public description = '';

	public id: number = 0;

	public link: string = '';

	public connect: ConnectInfo | null = null;

	public currentUserId: number = 0;

	public isDropdownOpen1 = false;

	public isDropdownOpen2 = false;

	public waitRequest = false;

	constructor(
		protected localStorageService: LocalStorageService,
		protected router: Router,
		private userService: UsersApiService,
		private httpStatusCodeApiService: HttpStatusCodeApiService
	) {
		this.localStorageService
			.getUserId()
			.then((userId) => {
				if (userId === Number(this.userId)) {
					this.isRemote = false;
				}
			})
			.catch(() => {});
	}

	public ngOnInit(): void {
		/* this.getEvent(); */
		this.init();
	}

	private init() {
		const { name, id, features, description, link, connect } = this.user;

		this.userName = name;
		this.features = features;
		this.description = description;
		this.id = id;
		this.link = link;
		this.connect = connect;
		this.localStorageService
			.getUserId()
			.then((userId) => {
				this.currentUserId = userId;
			})
			.catch(() => {});
	}

	public suggestFriendship(): void {
		if (this.waitRequest) {
			return;
		}
		this.waitRequest = true;
		this.localStorageService
			.getUserId()
			.then((userId) => {
				this.userService
					.suggestFriendship(userId, this.id)
					.subscribe((response) => {
						this.waitRequest = false;
						this.httpStatusCodeApiService.setInfo('Запрос о добавлении в друзья отправлен.');
						this.connect = { approve: false, sender: false };
					});
			})
			.catch(() => {});
	}

	public approveFriendship(ok: boolean): void {
		if (this.waitRequest) {
			return;
		}
		this.waitRequest = true;

		if (ok) {
			this.userService
				.approveFriendship(this.user.id)
				.subscribe((response) => {
					this.waitRequest = false;
					this.httpStatusCodeApiService.setSuccess('Вы успешно одобрили заявку в друзья.');
					this.connect = { approve: true, sender: true };
				});
		} else {
			this.userService
				.rejectFriendship(this.user.id)
				.subscribe((response) => {
					this.waitRequest = false;
					this.httpStatusCodeApiService.setSuccess('Вы успешно удалил пользователя из своих друзей');
					this.connect = null;
				});
		}
	}

	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const dropdownContent1 = document.querySelector(
			'.profile-card__settings_1 .dropdown-content'
		);
		const dropdownContent2 = document.querySelector(
			'.profile-card__settings_2 .dropdown-content'
		);
		const clickedInside1 = dropdownContent1?.contains(event.target as Node);
		const clickedInside2 = dropdownContent2?.contains(event.target as Node);

		if (!clickedInside1) {
			this.isDropdownOpen1 = false;
		}
		if (!clickedInside2) {
			this.isDropdownOpen2 = false;
		}
	}

	public exit(): void {
		this.localStorageService.clear();
		this.router.navigateByUrl('/');
	}

	public goToBot(): void {
		window.location.href = 'https://t.me/porebric';
	}
}
