import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { ErrorApiResponse } from "../../interfaces/api";

@Injectable({
	providedIn: 'root'
})

export class HttpStatusCodeApiService {
	public errorBody$ = new Subject<ErrorApiResponse>();

	public successBody$ = new Subject<string>();

	public infoBody$ = new Subject<string>();

	public setError(error: ErrorApiResponse): void {
		this.errorBody$.next(error);
	}

	public setSuccess(message: string): void {
		this.successBody$.next(message);
	}

	public setInfo(message: string): void {
		this.infoBody$.next(message);
	}
}